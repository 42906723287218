import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const PostPage: React.FC<PageProps<Queries.PostListByCategoryQuery>> = ({
  data,
}) => {
  return (
    <Layout>
      <h1>{data.microcmsCategory?.name}</h1>
      <ul>
        {data.allMicrocmsPosts.nodes.map((node) => {
          return (
            <li key={node.postsId}>
              <Link to={`/post/${node.postsId}`}>
                <span>{node.title}</span>
                <time>（公開日：{node.publishedAt}）</time>
                <span>{node.author}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query PostListByCategory($slug: String) {
    microcmsCategory(slug: { eq: $slug }) {
      name
    }
    allMicrocmsPosts(filter: { category: { slug: { eq: $slug } } }) {
      nodes {
        author
        postsId
        publishedAt(formatString: "YYYY/MM/DD")
        title
      }
    }
  }
`;

export default PostPage;

export const Head: HeadFC<Queries.PostQuery> = ({ data }) => (
  <Seo title={`${data.microcmsPosts?.category?.name}の投稿一覧`} />
);
